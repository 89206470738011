import * as React from "react"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useStaticQuery, graphql } from "gatsby"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";

import Layout from "../components/layout"
import Seo from "../components/seo"
import Image from "../components/image"

// スタイルの記述をする
const useStyles = makeStyles({
  outer: {
    width: "100%",
    height: "100%",
    position: "absolute", 
    top: 0,
    left: 0
  },

  inner: {
    display: "grid",
    paddingLeft: "5vw",
    paddingRight: "5vw",
    alignItems: "center",
    //textAlign: "center"
  },

  baseStyles: {
    position: 'relative',
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'repeat-y',
    backgroundSize: 'cover',
    margin: 0,
    padding: 0,
  },

  catchphrase: {
    color: "white",
  },
  title: {
    color: "white",
    marginBottom: '4vh',
    fontWeight: 'bold'
  },
  subTitle: {
    color: "white",
    marginBottom: '4vh'

  },

});

const BackgroundSection = (props) => {

  const title = props.site.siteMetadata?.title
  const catchphrase = props.site.siteMetadata?.catchphrase
  const description = props.site.siteMetadata?.description

  const classes = useStyles();
  return (
    <div style = {{position: "relative", top: 0}}>
      <div
        style={{minHeight: 300}}
      />
        <div className={classes.outer}>
          
          <div className={classes.inner} style={{minHeight: props.breakpoints.sm ? "30vh" : "30vh"}}>
            <div style={{maxWidth: props.breakpoints.sm ? "100%" : props.breakpoints.md ? "100%" : "100%"}}>
              <div>
                <div className={classes.catchphrase} style={{fontSize: props.breakpoints.sm ? 18 : props.breakpoints.md ? 26 : 28}}>{catchphrase}</div>
                <div className={classes.title} style={{fontSize: props.breakpoints.sm ? 30 : props.breakpoints.md ? 44 : 48}}>{title}</div>
              </div>
              <div className={classes.subTitle} style={{fontSize: props.breakpoints.sm ? 14 : props.breakpoints.md ? 16 : 22}}> {description}</div>
              
              <div>
                <span>
                  <a href= {`${process.env.GATSBY_AWS_COGNITO_LOGIN}`} rel="noreferrer" style = {{paddingLeft: 5,textDecoration: "none", paddingRight: 10}}>
                    <Button style={{fontSize: 16, width: 300}} variant="contained" color="secondary" endIcon={<ArrowForwardIcon />}>システムを使用する</Button>
                  </a>
                </span>
              </div> 
            </div>
          </div>
        </div>
    </div>
  )
}

const IndexPage = (props) => {

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            catchphrase
          }
        }
      }
    `
  )

  const breakpoints = useBreakpoint();
  return (
  <Layout location={props.location} breakpoints = {breakpoints}>
    <Seo title={site.siteMetadata.catchphrase} />
    <div>
    <div  style={{position: "fixed", top: 0, minWidth: "100%", minHeight: 460, height: breakpoints?.sm ? "50vh" : "40vh", zIndex: -1}}><Image filename="carrot_eyecatch.jpeg" style={{height: "inherit", minHeight: "inherit"}}/></div>
    <BackgroundSection site= {site} breakpoints = {breakpoints} style={{opacity: '0.8'}}/>
    </div>
  </Layout>
  )
}

export default IndexPage
